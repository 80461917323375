exports.components = {
  "component---src-components-parts-post-js": () => import("./../../../src/components/parts/Post.js" /* webpackChunkName: "component---src-components-parts-post-js" */),
  "component---src-components-parts-posts-js": () => import("./../../../src/components/parts/Posts.js" /* webpackChunkName: "component---src-components-parts-posts-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mylist-js": () => import("./../../../src/pages/mylist.js" /* webpackChunkName: "component---src-pages-mylist-js" */),
  "component---src-pages-sitepolicy-js": () => import("./../../../src/pages/sitepolicy.js" /* webpackChunkName: "component---src-pages-sitepolicy-js" */)
}

