import React from "react";
// import Mapbox from "./src/components/mapbox"
// import Layout from "./src/components/parts/layout"
import DarkMode from "./src/components/parts/darkMode"

import ContextProvider from './src/components/context/contextProvider';

export const wrapPageElement = ({ element }) => {
    return (
      <>
        <ContextProvider>
        <div className="absolute top-20 right-2 z-20 md:right-5">
        {/* <DarkMode /> */}
        </div>
          {element}
        </ContextProvider>
      </>
    );
  };