import React, {
  useState,
  createContext,
  useContext,
  useReducer,
  useRef,
} from 'react';
import reducer from './reducer';

const initialState = {
  points: [],
  hotels: [],
  filteredPoints: [],
  filteredHotels: [],
  filteredEvents: [],
  filteredEat: [],
  filteredWlan: [],
  filteredSaved: [],
  favoritesList: null,
  selectedPoint:null,
  yourLocationCoordinates: null,
  yourLocationAddress: null,
  selectCategory: null,
  distanceFilter: 0.300,
  maxDistance: 1000,
  lasttwohotels: [],
  sliderdir: null
}

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [checked, setChecked] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [favorites, setFavorites]  = useState(false)
  const [resetCoords, setResetCoords] = useState(false)
  const [miles, setMiles] = useState(false)
  const [showPopup, setShowPopup] = useState(null)
  const [value, setValue] = useState("about")
  const [open, setOpen] = useState(true)
  const [drawerposition, setDrawerposition] = useState(null)
  const [hoteldist, setHoteldist] = useState(0)
  const [selectedHotel, setSelectedHotel] = useState(null)
  const [selectedPinId, setSelectedPinId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [favmsg, setFavmsg] = useState(null)
  const [opendistcon, setOpendistcon] = useState(false)
  const [preselect, setPreselect] = useState(false)
  const [radialdist, setRadialdist] = useState(0)
  const [selectedCat, setSelectedCat] = useState("tokyo")
  const [topPage, setTopPage] = useState(true)
  const [hotelIndex, setHotelIndex] = useState(0)
  const [swiper, setSwiper] = useState()
  const [area, setArea] = useState(null)
  const [spinner, setSpinner] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const mapRef = useRef()
  const containerRef = useRef()

  const values = {
        state,
        dispatch,
        mapRef,
        containerRef,
        checked,
        setChecked,
        isOpen,
        setIsOpen,
        swiper,
        setSwiper,
        favorites,
        setFavorites,
        resetCoords,
        setResetCoords,
        miles,
        setMiles,
        showPopup,
        setShowPopup,
        value,
        setValue,
        open,
        setOpen,
        drawerposition,
        setDrawerposition,
        hoteldist,
        setHoteldist,
        selectedHotel,
        setSelectedHotel,
        selectedPinId,
        setSelectedPinId,
        favmsg,
        setFavmsg,
        anchorEl,
        setAnchorEl,
        opendistcon,
        setOpendistcon,
        preselect,
        setPreselect,
        selectedCat,
        setSelectedCat,
        hotelIndex,
        setHotelIndex,
        area,
        setArea,
        spinner,
        setSpinner,
        checkedValues,
        setCheckedValues
  }

  return (
    <Context.Provider value={values}>
     {children}
    </Context.Provider>
  )
}

export default ContextProvider;

