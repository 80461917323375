/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import * as React from "react"
// import { BrowserRouter } from 'react-router-dom'
import './src/styles/global.css'

// export const wrapPageElement = ({ element }) => {
//   return <BrowserRouter>{element}</BrowserRouter>
// }

export { wrapPageElement } from "./gatsby-ssr";

export const onInitialClientRender = () => {
    setTimeout(function() {
         document.getElementById("___loader").style.display = "none"
    }, 150)
}